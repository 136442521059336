import { ConversationItem } from "../../api";
import styles from "./FollowUpQuestionButtons.module.css";

interface FollowUpQuestionsButtonProps {
    conversationItem: ConversationItem;
    onFollowUpQuestionClicked : (question: string) => void;
}

const FollowUpQuestionButton = ({ conversationItem, onFollowUpQuestionClicked }: FollowUpQuestionsButtonProps) => {    
    const followUpQuestions = conversationItem.response.choices[0].context.followup_questions;

    return (
        <>
            {followUpQuestions && followUpQuestions.length > 0 && followUpQuestions.map((question) => (
            <button
                key={question}
                className={styles.followUpQuestion}
                title={question}
                onClick={() => onFollowUpQuestionClicked(question)}
            >
                {question}
            </button>
            ))}
        </>
    );
};

export default FollowUpQuestionButton;
